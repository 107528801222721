<template>
  <assessment-form
    v-if="assessmentData && assessmentData != null"
    title="Edit Assessment"
    description="Complete the following steps and click Update to update the assessment."
    :handleSubmitClick="updateTPRAssessmentAndSetData"
    :isLoading="isLoading"
    :preloadData="assessmentData"
    @set-loading="handleLoading"
  />
</template>

<script>
import ResponseMixins from "../../../mixins/ResponseMixins";
import ThirdPartyRisksRAMixins from "../../../mixins/ThirdPartyRisksRAMixins";
import AssessmentForm from "./components/AssessmentForm.vue";
export default {
  mixins: [ThirdPartyRisksRAMixins, ResponseMixins],
  components: { AssessmentForm },
  data() {
    return {
      isLoading: false,
      assessmentData: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getAssessmentDetailsAndSetData(this.$route.params.id);
    },

    handleLoading(value) {
      this.isLoading = value;
    },

    getAssessmentDetailsAndSetData(assessmentId) {
      this.isLoading = true;
      this.getAssessmentDetails(assessmentId)
        .then((res) => {
          console.log("Assessment Details", res.data.data);
          this.assessmentData = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    updateTPRAssessmentAndSetData(data) {
      this.isLoading = true;
      this.updateTPRAssessment(data, this.$route.params.id)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
          this.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style></style>
